<template>
  <div class="full">
    <section class="p_f od_top">
      <div class="order_nav d_f ali_c">
        <img src="../img/order_back.png" alt="" class="cur_p" @click="goBackList">
        <span>订单详情</span>
      </div>
      <div class="top_content">
        <div class="d_f ali_c">
          <template v-if="info[0].orderStatus == 2 && info[0].closeType == 2">
            <div>
              <h3 class="title">订单已取消</h3>
              <h5 class="sub">酒店暂无您预订的房型，已自动退还支付订金。</h5>
            </div>
          </template>
          <template v-else>

            <template v-if="info[0].refundStatus === null || info[0].refundStatus === 0">
              <!--待付款-->
              <template v-if="info[0].orderStatus == 1 || info[0].orderStatus == 0">
                <div>
                  <h3 class="title">待付款</h3>
                  <h5 class="sub">超时后，订单将自动关闭。</h5>
                </div>
                <div class="flex_1 t_r cut_down" v-if="cutDownTime>0">剩余时间：<van-count-down @finish="reloadInfo" class="d_i" :time="cutDownTime"/></div>
              </template>
              <!--已取消-->
              <template v-if="info[0].orderStatus == 2">
                <div>
                  <h3 class="title" v-if="info[0].closeType == 3">交易关闭</h3>
                  <h3 class="title" v-else>订单已取消</h3>
                  <!--<h5 class="sub">{{info[0].closeReason}}</h5>-->
                  <h5 class="sub" v-if="info[0].closeType == 1">订单已关闭</h5>
                  <h5 class="sub" v-if="info[0].closeType == 2">酒店暂无您预订的房型，已自动退还支付订金。</h5>
                  <h5 class="sub" v-if="info[0].closeType == 3">订单超时已关闭</h5>
                </div>
              </template>
              <!--已消费-->
              <template v-if="info[0].orderStatus == 7">
                <div>
                  <h3 class="title">已消费</h3>
                  <h5 class="sub">欢迎下次入住</h5>
                </div>
              </template>
              <!--待确认-->
              <template v-if="info[0].orderStatus == 4">
                <div>
                  <h3 class="title">已付款</h3>
                  <h5 class="sub">订单预订成功，酒店正在确认房型中...</h5>
                </div>
              </template>
              <!--预订成功-->
              <template v-if="info[0].orderStatus == 5">
                <div>
                  <h3 class="title">酒店已确认，待入住</h3>
                  <h5 class="sub">请携带相关证件办理入住。</h5>
                </div>
              </template>

            </template>
            <!--退款-->
            <template v-else>
              <!--退款审核中-->
              <template v-if="info[0].orderRefundStatus == 1">
                <div>
                  <h3 class="title">退款审核中</h3>
                  <h5 class="sub">退款申请已受理，正在审核中，请耐心等待。</h5>
                </div>
              </template>
              <!--退款失败-->
              <template v-if="info[0].orderRefundStatus == 5 || info[0].orderRefundStatus == 8">
                <div>
                  <h3 class="title">退款失败</h3>
                  <h5 class="sub">商家拒绝了您的退款申请。</h5>
                </div>
              </template>
              <!--退款成功-->
              <template v-if="info[0].orderRefundStatus == 6">
                <div>
                  <h3 class="title d_f ali_c">
                    <span>商家已退款</span>
                    <div class="flex_1 t_r d_f ali_c hod_right">
                      <div class="cur_p flex_1"  @click="goToED('/refundProgress',{orderNumber:orderNumber,hotelName:hotelInfo.hotelName})">
                        <span>退款进度</span>
                        <img src="../img/hod_right.png" alt="">
                      </div>
                    </div>
                  </h3>
                  <h5 class="sub">退款申请已审核通过，商家已退款预计1-2个工作日到账</h5>
                </div>
              </template>
              <div v-if="info[0].orderRefundStatus != 6" class="flex_1 t_r d_f ali_c hod_right">
                <div class="cur_p flex_1"  @click="goToED('/refundProgress',{orderNumber:orderNumber,hotelName:hotelInfo.hotelName})">
                  <span>退款进度</span>
                  <img src="../img/hod_right.png" alt="">
                </div>
              </div>
            </template>
          </template>
        </div>
        <!--待付款-->
        <div class="white_card" v-if="info[0].orderStatus == 1 || info[0].orderStatus == 0">
          <div class="head">
            <span>取消规则</span>
            <!--<span class="black">{{info[0].beginDate.split('-')[1]+'月'+info[0].beginDate.split('-')[2]+'日'}}18:00前可免费取消</span>-->
            <span class="black">{{info[0].refundNotice}}</span>
          </div>
          <div class="btn_out d_f ali_c j_s t_a">
            <div class="btn" @click="cancelOrder">取消订单</div>
            <div class="btn ac" @click="goToPay">去支付￥{{(totalPrice - totalIntegralMoney) / 100}}</div>
          </div>
        </div>
        <!--已取消,待确认-->
        <div class="btn ac full t_a mt_15 cur_p" @click="$router.push('/hotelInner?sellerId='+info[0].sellerId)" v-if="(info[0].orderStatus == 7 && info[0].isPreview == 1) || (info[0].orderRefundStatus == 6 && info[0].refundStatus !== null) || info[0].orderStatus == 2 || (info[0].orderStatus == 4 && (info[0].refundStatus === null || info[0].refundStatus === 0))">再次预订</div>
        <!--预订成功-->
        <div class="btn_out d_f ali_c j_s t_a two mt_15" v-if="(info[0].orderStatus == 5 &&  (info[0].refundStatus === null || info[0].refundStatus === 0))">
          <div class="btn ac cur_p" @click="applyRefund">申请退款</div>
          <div class="btn ac cur_p" @click="$router.push('/hotelInner?sellerId='+info[0].sellerId)">再次预订</div>
        </div>
        <!--已消费-->
        <div class="btn_out d_f ali_c j_s t_a two mt_15" v-if="info[0].orderStatus == 7 && info[0].isPreview == 0 && info[0].refundStatus == null">
          <div class="btn ac cur_p" @click="isShowComment = true">立即评价</div>
          <div class="btn ac cur_p" @click="$router.push('/hotelInner?sellerId='+info[0].sellerId)">再次预订</div>
        </div>
        <!--<div class="btn ac cur_p" @click="isShowComment = true">立即评价</div>-->
        <!--退款-->
        <template v-if="info[0].refundStatus !== null">
          <div class="btn ac full t_a mt_15 cur_p" v-if="info[0].orderRefundStatus == 1" @click="recallOrder">撤销申请</div>
          <div class="refuse mt_15" v-if="info[0].orderRefundStatus == 5 || info[0].orderRefundStatus == 8">
            拒绝理由：{{info[0].missCause}}
          </div>
        </template>
      </div>
    </section>
    <section class="h_content" :class="info[0].orderStatus == 0 || info[0].orderStatus == 1 ? 'tall' : ''">
      <!--费用明细那一块-->
      <div class="bc_w price_out">
        <div class="d_f ali_c">
          <span class="title" v-if="info[0].orderStatus == 0
          || info[0].orderStatus == 1
          || info[0].orderRefundStatus == 8
          || (info[0].refundStatus !== null && info[0].orderRefundStatus == 1)
          || (info[0].orderStatus == 2 && info[0].closeType == 1)
           || (info[0].orderStatus == 7 && (info[0].refundStatus === null || info[0].refundStatus === 0))
          || (info[0].orderStatus == 2 && info[0].closeType == 3)">在线支付</span>
          <span class="title" v-if="info[0].orderStatus == 4 || (info[0].orderStatus == 5 && (info[0].refundStatus === null || info[0].refundStatus === 0))">已支付</span>
          <span class="title" v-if="(info[0].orderStatus == 2 && info[0].closeType == 2 && (info[0].refundStatus === null || info[0].refundStatus === 0)) || (info[0].refundStatus !== null && info[0].orderRefundStatus == 6)">已退款</span>
          <!--<span class="title" v-if="(info[0].refundStatus != null && info[0].orderRefundStatus == 6)">退款中</span>-->
          <span class="red flex_1">￥{{(totalPrice - totalIntegralMoney) /100}}<span class="red_black" v-if="(info[0].refundStatus !== null && info[0].orderRefundStatus == 6)">（预计1-2个工作日内到账）</span></span>
          <div class="d_f ali_c cur_p price_detail" @click="goToED('/expenseDetails',priceQueryLink)">
            <span>费用明细</span>
            <img src="../img/right_orange.png" alt="">
          </div>
        </div>
        <div class="back_tip" v-if="(info[0].refundStatus !== null && info[0].orderRefundStatus == 6)">订单已取消，无需扣款费用</div>
        <div class="order_num d_f ali_c">
          <span class="title">订单号</span>
          <span class="num">{{orderNumber}}</span>
          <div class="cur_p t_a copy_btn" @click="doCopy" :data-clipboard-text="info[0].orderNumber">复制</div>
        </div>
        <div class="td_rule d_f" v-if="(info[0].refundStatus !== null && info[0].orderRefundStatus == 6)">
          <div class="td_title">退订规则：</div>
          <div class="flex_1">{{info[0].refundNotice}}</div>
          <!--<div class="flex_1">预订成功后当日18:00点前可免费取消，如超过 18:00不予退订。</div>-->
        </div>
        <!--核销码  应需求取消展示核销码-->
        <!--<div class="hod_hx t_a" v-if="(info[0].orderStatus == 5 && info[0].refundStatus === null)">
          核销码：{{info[0].writeOffCode}}
        </div>-->
        <!--步骤条-->
        <!--<div>
          <Steps :Steps="registSpets" :SetData="SetData" />
        </div>-->
      </div>
      <div class="bc_w d_out">
        <!--酒店信息done-->
        <div class="title d_f ali_c">
          <span class="flex_1 text_over">{{hotelInfo.hotelName}}</span>
          <div class="d_f ali_c price_detail cur_p" @click="goToED('/hotelDetail',{sellerId:sellerId})">
            <span>酒店详情</span>
            <img src="../img/right_orange.png" alt="">
          </div>
        </div>
        <div class="sub_title">{{hotelInfo.address}}</div>
        <div class="map_out d_f ali_c mt_15 bb">
          <div class="d_f ali_c map cur_p" @click="goMap()">
            <img src="../img/hod_map.png" alt="">
            <span>地图/导航</span>
          </div>
          <div class="cut">|</div>
          <a class="d_f ali_c contact cur_p" :href="'tel:'+hotelInfo.telephone">
            <img src="../img/hod_contact.png" alt="">
            <span>联系酒店</span>
          </a>
        </div>
        <!--房型信息done-->
        <div class="bb hod_fs">
          <div class="title d_f ali_c">
            <span class="flex_1">{{info[0].commoditytName}}</span>
            <div class="d_f ali_c price_detail cur_p" @click="showRoomPop">
              <span>查看房型</span>
              <img src="../img/right_orange.png" alt="">
            </div>
          </div>
          <div class="d_f ali_c time_out">
            <div class="time_title">入住时间</div>
            <div class="time_date">{{info[0].beginDate.split('-')[1]+'月'+info[0].beginDate.split('-')[2]+'日'}}</div>
            <div class="time_remark">---</div>
          </div>
          <div class="d_f ali_c time_out">
            <div class="time_title">离店时间</div>
            <div class="time_date">{{info[0].endDate.split('-')[1]+'月'+info[0].endDate.split('-')[2]+'日'}}</div>
            <div class="time_remark">{{'共'+info.length+'间'+$getDaysBetween(new Date($renderTime(info[0].beginDate)).getTime(),new Date($renderTime(info[0].endDate)).getTime())+'晚'}}</div>
          </div>
        </div>

        <!--房客信息done-->
        <div class="hod_fk bb">
          <div class="d_f ali_c" v-for="(item,index) in info" :key="index">
            <div class="title d_f ali_c">
              <span>入住房客</span>
              <div>{{index + 1}}</div>
            </div>
            <div class="name">{{item.visitorName}}</div>
          </div>
        </div>
        <!--三个item-->
        <div class="bb hod_th d_f ali_c">
          <div class="th_title">联系电话</div>
          <span>{{info[0].reservationPersonTelephone}}</span>
        </div>
        <div class="bb hod_th d_f ali_c">
          <div class="th_title">预计到店</div>
          <span>{{info[0].beginDate.split('-')[1]+'月'+info[0].beginDate.split('-')[2]+'日 '+info[0].arriveTime+'前'}}</span>
        </div>
        <div class="bb hod_th d_f ali_c">
          <div class="th_title">开具发票</div>
          <span>请离店后，联系酒店前台开具发票(仅支持普票)</span>
        </div>
        <!--入住说明-->
        <div class="hod_in_out">
          <div class="hodi_title">入住说明</div>
          <div>1. 请携带所有入住人的有效身份证，报入住人姓名，于{{info[0].beginDate.split('-')[1]+'月'+info[0].beginDate.split('-')[2]+'日'}} 18:00前办理入住，{{info[0].endDate.split('-')[1]+'月'+info[0].endDate.split('-')[2]+'日'}}13:00前退房。</div>
          <div>2. 如需提前办理入住或延迟退房请联系酒店。</div>
          <!--<div>3. {{info[0].beginDate.split('-')[1]+'月'+info[0].beginDate.split('-')[2]+'日'}}前可免费取消。</div>-->
        </div>
      </div>

      <section class="bottom_tip d_f" v-if="info[0].orderStatus == 4">
        <img src="../img/hod_tip.png" alt="">
        <span>酒店正在确认房型，如该房型已预订出去，我们将全额返还订金。</span>
      </section>
      <section class="bottom_tip d_f"  v-if="info[0].orderStatus == 2 && info[0].closeType == 2">
        <img src="../img/hod_tip.png" alt="">
        <span>平台已退还您的支付订金，预计24小时内到账，请注意查收， 如未到账请联系酒店处理。</span>
      </section>
      <timeTip v-if="(info[0].orderStatus == 5 && (info[0].refundStatus === null || info[0].refundStatus === 0)) || info[0].orderRefundStatus == 8"></timeTip>
    </section>
    <!--房型弹窗-->
    <room v-if="isShowRoom" @close="isShowRoom = false" :roomPopInfo="roomPopInfo"></room>
    <!--退款原因-->
    <refundReason v-if="isShowRR" @close="reloadInfo" :orderNumber="info[0].orderNumber"></refundReason>

    <!--评价-->
    <hotelComment v-if="isShowComment" @close="isShowComment = false" @save="reloadInfo" :orderId="info[0].orderId"></hotelComment>

  </div>
</template>

<script>
  // import Steps from "../../hotel/component/steps";
  import room from "../../hotel/component/room";
  import timeTip from "../../hotel/component/time_tip";
  import refundReason from "../../hotel/component/refundReason";
  import { Dialog,Toast, CountDown } from 'vant';
  import apis from '../utils/apis'
  import hotelComment from "../../hotel/component/hotelComment";

  export default {
    name: "hotelOrderDetail",
    components: {
      // Steps,
      room,
      timeTip,
      refundReason,
      hotelComment,
      'van-count-down':CountDown,
      'van-dialog':Dialog
    },
    data() {
      return {
        registSpets:0,
        SetData : ["用户信息", "魔童哪吒", "齐天大圣", "赤脚大仙", "我爱中国"],
        cutDownTime:0,
        activeStep:2,
        isShowRoom:false,
        isShowRR:false,
        isShowComment:false,
        orderNumber:this.$route.query.orderNumber,
        info:[{beginDate:'',endDate:''}],
        sellerId:null,
        hotelInfo:{},
        roomPopInfo:{},
        priceArr:[],
        totalPrice:0,
        priceQuery:{},
        priceQueryLink:{},
        totalIntegralMoney: 0 // 积分抵扣金额
      }
    },
    mounted(){
      this.findHotelOrderDetail();
    },
    created(){

      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
    },
    methods: {
      reloadInfo(){
        this.isShowRR = false;
        this.isShowComment = false;
        this.findHotelOrderDetail();
      },
      //跳转酒店列表
      goBackList(){
        let _t = this;
        _t.$router.go(-1)

      },
      //去支付
      goToPay(){
        let _t = this;
        _t.$router.push({
          path:'/hotelPay',
          query:{
            price:_t.totalPrice ? (_t.totalPrice - _t.totalIntegralMoney) : 0,
            buyNum:_t.info.length,
            hotelOrderNum:_t.info[0].orderNumber,
          }
        })
      },
      //撤销申请
      recallOrder(){
        let _t = this;

        Dialog.confirm({
          cancelButtonText: '我再想想',
          message: '确定撤销申请吗？',
        })
          .then(() => {
            _t.$post({
              url: apis.recallHotelUserRefundApply,
              params:{
                token:_t.$global.token,
                orderNumber: _t.orderNumber,
              }
            }).then(res => {
              Toast('操作成功');
              _t.findHotelOrderDetail();
            }).catch(err =>{
              Toast(err.message);
            })
          })
          .catch(() => {
            // on cancel
          });
      },
      //申请退款
      applyRefund(){
        Dialog.confirm({
          cancelButtonText: '我再想想',
          message: '确定申请退款吗？',
        })
          .then(() => {
            this.isShowRR = true;
            // on confirm
          })
          .catch(() => {
            // on cancel
          });
      },
      // 费用明细、退款进度跳转
      goToED(ph,query){
        this.$router.push({
          path:ph,
          query:query
        })
      },
      //查询费用明细
      queryPriCalendarByRoomIdAndDate(){
        let _t = this;
        _t.$get({
          url:apis.queryPriCalendarByRoomIdAndDate,
          params: _t.priceQuery
        }).then(res => {
          _t.priceArr = res;
          let num = 0;
          _t.priceArr.map(item => {
            let price = item.sellPrice ? item.sellPrice : 0;
            num += price*_t.info.length;
          });
          _t.totalPrice = num;
        });
      },
      //取消订单done
      cancelOrder(){
        let me = this;
        Dialog.confirm({
          title: '提示',
          message: '确认取消订单？',
        }).then(() => {
          me.$get({
            url:apis.cancelOrder,
            params: {
              orderId: me.info[0].orderId,
              token:me.$global.token,
            }
          }).then(res => {
            Toast('取消成功');
            setTimeout(function () {
              me.findHotelOrderDetail()
            },1000)
          });
        })

      },
      //复制订单号done
      doCopy(){
        let _t = this;
        let clipboard = new _t.$Clipboard(".copy_btn"); //在main.js中引用
        clipboard.on("success", e => {
          // 释放内存
          Toast('复制成功')
          clipboard.destroy();
        });
        clipboard.on("error", e => {
          // 不支持复制
          Toast({
            message: "该浏览器不支持自动复制",
            type: "warning"
          });
          // 释放内存
          clipboard.destroy();
        });
      },
      // 导航done
      goMap(){
        let isMap = JSON.parse(localStorage.getItem('map')) ? true : false;
        if(!isMap) return Toast('定位失败，无法导航');
        let locations = JSON.parse(localStorage.getItem('map')).position;
        let ipGo=null;
        // ipGo = `https://uri.amap.com/navigation?from=${locations.lng},${locations.lat},起点&to=${this.info[0].longitude},${this.info[0].latitude},${this.info[0].hotelName}&callnative=1`
        ipGo = `https://www.amap.com/dir?from[name]=起点&from[lnglat]=${locations.lng},${locations.lat}&to[name]=${this.info[0].hotelName}&to[lnglat]=${this.info[0].longitude},${this.info[0].latitude}&policy=1&type=car&callnative=1`

        // window.open(ipGo)
        this.$router.push({
          path:'/outWindow',
          query:{
            interlinkage:encodeURI(ipGo)
          }
        })
      },
      //房型弹窗done
      showRoomPop(){
        this.roomPopInfo.roomId = this.info[0].commodityId;
        this.roomPopInfo.beginTime = this.info[0].beginDate;
        this.roomPopInfo.endTime = this.info[0].endDate;
        this.isShowRoom = true;
      },
      //订单详情done
      findHotelOrderDetail(){
        let _t = this;
        _t.$get({
          url:apis.findHotelOrderDetail,
          params: {
            orderNumber: _t.orderNumber,
            token:_t.$global.token,
          }
        }).then(res => {
          _t.info = JSON.parse(JSON.stringify(res));
          _t.sellerId = res[0].sellerId;
          _t.cutDownTime = new Date(_t.$renderTime(res[0].creatorTime)).getTime()+15 * 60 * 1000-new Date().getTime();
          let endTime = new Date(_t.$renderTime(_t.info[0].endDate)).getTime()-24*3600*1000 ;
          let endDate = new Date(_t.$renderTime(_t.info[_t.info.length-1].endDate)).getTime()-24*3600*1000 ;
          _t.priceQuery = {
            roomId:_t.info[0].commodityId,
            startTime:_t.info[0].beginDate,
            endTime:_t.$formatDate(new Date(endTime),'yyyy-MM-dd'),
          };
          _t.priceQueryLink = {
            roomId:_t.info[0].commodityId,
            startTime:_t.info[0].beginDate,
            endTime:_t.$formatDate(new Date(endTime),'yyyy-MM-dd'),
            num:_t.info.length,
            orderNumber:this.$route.query.orderNumber,
            endDate: _t.$formatDate(new Date(endDate),'yyyy-MM-dd')
          };
          _t.findInfo();
          _t.queryPriCalendarByRoomIdAndDate();
          let totalIntegralMoney = 0
          _t.info.map( item => {
            totalIntegralMoney += item.totalDiscountAmount
          })
          _t.totalIntegralMoney = totalIntegralMoney
        });
      },
      //获取酒店详情done
      findInfo(){
        let _t = this;
        _t.$get({
          url:apis.findInfo,
          params: {
            orderNumber: _t.orderNumber,
            sellerId:_t.sellerId || 105262,
          }
        }).then(res => {
          _t.hotelInfo = res;
        });
      },
    }
  }
</script>

<style scoped lang="scss">
  .bottom_tip{
    .tip_grey{
      font-size:12px;
      font-weight:500;
      color:rgba(142,142,142,1);
      line-height:17px;
      >div{
        margin-bottom: 9px;
      }
    }
    .tip_title{
      font-size:13px;
      font-weight:500;
      color:rgba(62,62,62,1);
      line-height:17px;
      margin-bottom: 12px;
    }
    img{
      width: 13px;
      height: 13px;
      margin-right: 7px;
      position: relative;
      top: 1px;
    }
    background-color: #f0f0f0;
    padding: 18px 22px;
    font-size:12px;
    font-weight:500;
    color:rgba(62,62,62,1);
  }
  .hod_in_out{
    .hodi_title{
      margin: 30px 0 13px 0;
      font-size:13px;
    }
    >div{
      margin-bottom: 15px;
    }
    font-size:12px;
    font-weight:500;
    color:rgba(62,62,62,1);
    margin-bottom: 25px;
  }
  .hod_th{
    font-size:12px;
    font-weight:500;
    color:rgba(62,62,62,1);
    .th_title{
      width: 85px;
      color:rgba(142,142,142,1);
    }
    padding: 17px 0;
  }
  .hod_fk{
    >div{
      margin-top: 19px;
    }
    padding-bottom: 19px;
    .name{
      font-size:12px;
      font-weight:500;
      color:rgba(62,62,62,1);
    }
    .title{
      div{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid #8E8E8E;
        color: #8E8E8E;
        text-align: center;
        font-size: 10px;
        line-height: 10px;
        box-sizing: border-box;
        margin-left: 6px;
      }
      margin-bottom: 0!important;
      width: 85px;
      font-size:12px;
      font-weight:500;
      color:rgba(142,142,142,1);
    }
  }
  .hod_fs{
    .time_out{
      font-size:12px;
      font-weight:500;
      color:rgba(62,62,62,1);
      .time_date{
        width: 60px;
      }
      .time_title{
        width:85px;
        color:rgba(142,142,142,1);
      }
      margin-bottom: 10px;
    }
    .title{
      font-size:13px;
      font-weight:500;
      color:rgba(62,62,62,1);
      margin-bottom: 18px;
    }
    padding-bottom: 10px;
  }
  .bb{
    border-bottom: 1px solid #D0D0D0;
  }
  .d_out{
    .map_out{
      .cut{
        margin-left: 43px;
        color: #D0D0D0;
        font-size: 10px;
      }
      .map,.contact{
        font-size:13px;
        font-weight:500;
        color:rgba(62,62,62,1);
        img{
          width: 15px;
          margin: 0 9px 0 40px;
        }
      }
      padding-bottom: 9px;
      margin-bottom: 18px;
    }
    .sub_title{
      font-size:12px;
      font-weight:500;
      color:rgba(62,62,62,1);
    }
    font-size:14px;
    font-weight:bold;
    color:rgba(62,62,62,1);
    .title{
      margin-bottom: 10px;
    }
  }
  .price_detail{
    img{
      margin-left: 7px;
      width: 6px;
    }
    font-size:13px;
    font-weight:500;
    color:rgba(255,187,80,1);
  }
  .h_content{
    .price_out{
      .hod_hx{
        height:35px;
        border:1px solid rgba(32,193,119,1);
        border-radius:3px;
        line-height: 35px;
        font-size:13px;
        font-weight:500;
        color:rgba(32,193,119,1);
        margin-top: 15px;
      }
      .td_rule{
        .td_title{
          color: #3E3E3E;
        }
        margin-top: 24px;
        font-size:13px;
        font-weight:500;
        color:#A0A0A0;
      }
      .back_tip{
        font-size:13px;
        font-weight:500;
        color:rgba(160,160,160,1);
        margin: 8px 0 13px 0;
      }
      .order_num{
        margin-top: 13px;
      }
      .copy_btn{
        width:35px;
        height:14px;
        border:1px solid rgba(192,192,192,1);
        border-radius:7px;
        margin-left: 19px;
        font-size:10px;
        line-height: 14px;
        font-weight:400;
        color:rgba(62,62,62,1);
      }
      .num{
        font-size:13px;
        font-weight:500;
        color:rgba(62,62,62,1);
      }
      .title{
        width: 60px;
        margin-right: 8px;
        font-size:13px;
        font-weight:500;
        color:rgba(62,62,62,1);
      }
      .red{
        font-size:15px;
        font-weight:500;
        color:rgba(255,42,0,1);
        .red_black{
          font-size:13px;
          font-weight:500;
          color:rgba(62,62,62,1);
        }
      }
      margin-bottom: 10px;
    }
    .bc_w{
      background-color: white;
      padding: 13px 20px;
    }
    height: 100%;
    box-sizing: border-box;
    padding-top: 180px;
    background-color: #f0f0f0;
    &.tall{
      padding-top: 255px;
    }
  }
  .mt_15{
    margin-top: 15px;
  }
  .od_top{
    .top_content{
      .refuse{
        height:35px;
        line-height: 35px;
        background:rgba(255,248,222,1);
        border:1px dotted rgba(255,172,65,1);
        border-radius:3px;
        padding: 0 10px;
        font-size:12px;
        font-weight:500;
        color:rgba(255,187,80,1);
      }
      .hod_right{
        position: relative;
        top: -15px;
        img{
          width: 7px;
          margin-left: 6px;
          position: relative;
          top: 2px;
        }
        font-size:13px;
        font-weight:bold;
        color:rgba(255,255,255,1);
      }
      .btn{
        &.full{
          width: 100%;
        }
        &.ac{
          background:rgba(255,187,80,1);
          color: white;
        }
        cursor: pointer;
        width:146px;
        height:35px;
        line-height: 35px;
        border:1px solid rgba(255,187,80,1);
        border-radius:3px;
        font-size:15px;
        font-weight:500;
        color:rgba(255,187,80,1);
      }
      .btn_out.two{
        .btn{
          width: 153px;
        }
      }
      .white_card{
        .head{
          .black{
            margin-left: 13px;
            color:#3E3E3E;
          }
          font-size:12px;
          font-weight:500;
          color:rgba(160,160,160,1);
          height: 40px;
          line-height: 40px;
          border-bottom: 1px solid #D0D0D0;
          margin-bottom: 17px;
        }
        margin-top: 13px;
        padding: 0 10px 18px 10px;
        background:rgba(255,255,255,1);
        border-radius:3px;
      }
      .cut_down{
        .van-count-down{
          font-size:17px;
          font-weight:bold;
          color:rgba(255,255,255,1);
          vertical-align: bottom;
        }
        font-size:17px;
        font-weight:bold;
        color:rgba(255,255,255,1);
      }
      .sub{
        font-size:13px;
        font-weight:500;
        color:rgba(255,255,255,1);
        margin-top: 14px;
      }
      .title{
        font-size:20px;
        font-weight:bold;
        color:rgba(255,255,255,1);
        .hod_right{
          top: 0;
        }
      }
      padding: 0 21px 15px 21px;
      background: linear-gradient(75deg, #7fdc4f, #1ddf50);
    }
    width: 100%;
    top: 0;
    left: 0;
    height: 180px;
    box-sizing: border-box;
  }
</style>
