<template>
  <!--房型弹窗组件-->
  <div class="full p_f">
    <div @click.stop="closeRoom" style="width: 100%;height: 30%"></div>
    <div class="room_out p_a">
      <img class="cur_p p_a close_room" src="../img/close_room.png" alt="" @click="closeRoom">
      <!--轮播-->
      <van-swipe @change="onChange" style="height: 167px" :autoplay="3000">
        <van-swipe-item v-for="(item,index) in info.attachments" :key="index">
          <van-image fit="fill" :src="item.url"/>
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator">
            {{ current + 1 }}/{{info.attachments.length}}
          </div>
        </template>
      </van-swipe>
      <div class="r_content" :class="roomPopInfo.showBtn ? 'has_btn' : ''">
        <div class="title">{{info.name}}</div>
        <div class="items">
          <div v-if="info.roomType.roomExtendAttr.useableArea">{{info.roomType.roomExtendAttr.useableArea}}m²</div>
          <div v-if="info.roomType.window>0">有窗</div>
          <div v-if="info.roomType.roomExtendAttr.network>0">WIFI</div>
          <div>{{info.roomType.capacity}}人</div>
          <div v-if="info.hotelBedType.bedDesc">{{info.hotelBedType.bedDesc}}</div>
          <div v-if="info.breakfast>0">提供早餐</div>
          <div v-if="info.roomType.roomExtendAttr.fridge>0">冰箱</div>
          <div v-if="info.roomType.roomExtendAttr.bottledWater>0">瓶装水</div>
          <div v-if="info.roomType.roomExtendAttr.kitchen>0">厨房</div>
          <div v-if="info.roomType.roomExtendAttr.microwaveOven>0">微波炉</div>
          <div v-if="info.roomType.roomExtendAttr.minibar>0">迷你酒吧</div>
          <div v-if="info.roomType.roomExtendAttr.bathtub>0">浴缸</div>
          <div v-if="info.roomType.roomExtendAttr.safeDepositBox>0">保险箱</div>
          <div v-if="info.roomType.roomExtendAttr.airCondition>0">空调</div>
          <div v-if="info.roomType.roomExtendAttr.airBlower>0">吹风机</div>
          <div v-if="info.roomType.roomExtendAttr.alldayHotWater>0">24小时热水</div>
          <div v-if="info.roomType.roomExtendAttr.shower>0">淋浴</div>
          <div v-if="info.roomType.roomExtendAttr.toiletries">洗漱用品</div>
          <div v-if="info.roomType.extrabed>0">可加床</div>
          <div v-if="info.roomType.washroom>0">卫生间</div>
        </div>
        <div class="cut_m">
          <div class="title">房型说明</div>
          <div class="c_content">房型图片仅代表其中部分房间以作为预订参考，实际提供房型信 息以文字信息为准，如文字信息不全或包含多种情况，请提前与 商家沟通确认后再下单预订。</div>
        </div>
        <div class="cut_m">
          <div class="title">儿童及加床</div>
          <div class="c_content">酒店允许携带儿童入住。 不接受18岁及以下客人在无监护人陪同的情况下入住。 加床及儿童政策取决于您所选的房型，若超过房型限定人数，或 携带儿童年龄不在政策范围内，需收取额外费用，提出额外要求 请线下和酒店进行沟通确认。</div>
        </div>
        <div class="cut_m">
          <div class="title">取消政策</div>
          <div class="c_content">
            <table class="t_a">
              <tr class="head">
                <td>北京时间</td>
                <td>政策</td>
              </tr>
              <tr class="green">
                <td>当日预订18:00前</td>
                <td>免费取消</td>
              </tr>
              <tr>
                <td>当日预订18:00后</td>
                <td>不可取消</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <!--底部按钮-->
      <div class="b_btn" v-if="roomPopInfo.showBtn">
        <div class="d_f ali_c j_s">
          <a :href="'tel:' + roomPopInfo.telephone">
            <img src="../img/hotel_contact.png" alt="">
            <div>问问酒店</div>
          </a>
          <div class="btn_cut"><div></div></div>
          <div class="flex_1 price"><span>￥{{roomPopInfo.minSellPrice ? roomPopInfo.minSellPrice/100 : 0}}</span><span class="small">/晚</span></div>
          <div class="btn d_f ali_b cur_p" @click="goOrder" v-if="roomPopInfo.showbtn">
            <span>立即预订</span>
          </div>
          <div class="btn d_f ali_b cur_p grey" v-else>
            <span>不可预订</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import apis from '../utils/apis'

  import { Toast, SwipeItem, Swipe, Image } from 'vant';
  export default {
    name: "room",
    components:{
      'van-swipe-item': SwipeItem,
      'van-swipe': Swipe,
      'van-image': Image,
    },
    props:{
      roomPopInfo:{
        type: Object,
        default(){
          return{}
        }
      },
    },
    data() {
      return {
        current:0,
        info:{
          attachments:[],
          hotelBedType:{},
          roomExtendAttr:{},
          roomType:{
            roomExtendAttr:{},
          },
        },
      }
    },
    created(){
      this.findHotelRoom();
    },
    methods: {
      goOrder(){
        this.$emit('goOrder');
      },
      //查询房间详情
      findHotelRoom(){
        this.roomPopInfo.endTime = isNaN(new Date(this.roomPopInfo.endTime)) ? this.roomPopInfo.endTime - 24*3600*1000 : new Date(this.roomPopInfo.endTime).getTime()-24*3600*1000 ;
        this.$get({
          url: apis.findHotelRoom,
          params: {
            roomId:this.roomPopInfo.roomId,
            beginTime:this.$formatDate(new Date(this.roomPopInfo.beginTime),'yyyy-MM-dd'),
            endTime:this.$formatDate(new Date(this.roomPopInfo.endTime),'yyyy-MM-dd'),
          },
        }).then(rsp => {
          this.info = rsp;
          if(!this.info.attachments.length){
            this.info.attachments.push({url:this.info.coverImgUrl})
          }
          this.info = JSON.parse(JSON.stringify(this.info))
        });
      },
      // 轮播
      onChange(index) {
        this.current = index;
      },
      // 关闭房型弹窗
      closeRoom(){
        this.$emit('close')
      }
    }
  }
</script>

<style scoped lang="scss">

  .b_btn{
    .price{
      text-align: right;
      font-size: 19px;
      font-weight: 500;
      color: #3E3E3E;
      padding-right: 30px;
      .small{
        font-size: 14px;
      }
    }
    position: absolute;
    .btn_cut{
      padding-left: 17px;
      div{
        width: 1px;
        height: 28px;
        background: #DEDEDE;
      }
    }
    .btn{
      img{
        width: 15px;
        margin-right: 4px;
      }
      .sub{
        font-size: 9px;
      }
      &.grey{
        background:rgba(142,142,142,1);
        color: white;
      }
      line-height: 34px;
      font-size:13px;
      font-weight:bold;
      color:rgba(255,255,255,1);
      height:34px;
      background:rgba(31,193,119,1);
      border-radius:17px;
      padding: 0 23px;
    }
    a{
      img{
        width: 13px;
        /*margin-bottom: 6px;*/
      }
      text-align: center;
      font-size:9px;
      font-weight:500;
      color:rgba(61,61,61,1);
    }
    padding: 10px 21px 0 21px;
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    width: 100%;
    height:51px;
    background:rgba(255,255,255,1);
    box-shadow: 0px 0px 2px 0px rgba(8, 1, 4, 0.3);
  }
  .r_content{
    .cut_m{
      table{
        tr{
          &.head{
            line-height: 31px;
            background-color: rgba(222,222,222,1);
          }
          line-height: 47px;
        }
        td{
          border: 1px solid #DEDEDE;
        }
        .green{
          color: #20C177;
        }
        font-size:13px;
        font-weight:500;
        color:rgba(62,62,62,1);
        table-layout: fixed;
        width: 100%;
        border-collapse: collapse;
      }
      .title{
        line-height: 50px;
        font-size:14px;
        font-weight:bold;
        margin-bottom: 0;
      }
      border-top: 1px solid #F0F0F0;
      font-size:12px;
      font-weight:500;
      color:rgba(62,62,62,1);
      line-height:17px;
      padding-bottom: 30px;
    }
    .items{
      >div{
        float: left;
        width: calc(100% / 3);
        margin-bottom: 19px;
      }
      &::after{
        content: ' ';
        display: block;
        clear: both;
      }
      padding-bottom: 14px;
      font-size:13px;
      font-weight:500;
      color:rgba(62,62,62,1);
    }
    .title{
      font-size:17px;
      font-weight:bold;
      color:rgba(62,62,62,1);
      margin-bottom: 30px;
    }
    box-sizing: border-box;
    height: calc(100% - 167px);
    overflow: auto;
    padding: 13px 22px;
    &.has_btn{
      height: calc(100% - 167px - 51px);
    }
  }
  .room_out{
    .custom-indicator {
      border-radius: 6px;
      position: absolute;
      right: 8px;
      bottom: 4px;
      text-align: center;
      height: 12px;
      line-height: 12px;
      width: 27px;
      font-size: 10px;
      background: rgba(0, 0, 0, 0.5);
      color: white;
    }
    height: 70%;
    width: 100%;
    left: 0;
    background-color: white;
    bottom: 0;
    animation: goToTop .5s;
  }
  .close_room{
    right: 11px;
    top: 11px;
    width: 17px;
    z-index: 999;
  }
  .full{
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.51) !important;
    z-index: 100;
  }

</style>
